import React, { useState } from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import {
  FaLongArrowAltRight,
  FaPhoneAlt,
  FaRegClock,
  FaRegEnvelopeOpen,
} from "react-icons/fa"
import { encode } from "../utils"

/**
 * Contact Form Component
 * This component is used to generate the leads contact form
 * This form is submitted to Netlify Forms using the name `contact-leads`
 * The fields used in this form are
 *   - Name
 *   - Email
 *   - Phone
 *   - Message
 * @param {Object} props
 */
const ContactForm = ({ email, phone }) => {
  // retrieves the background image
  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: { eq: "hero-gt.jpg" }) {
        sharp: childImageSharp {
          fluid(
            quality: 100
            maxWidth: 1920
            duotone: { highlight: "#282d3a", shadow: "#282d3a", opacity: 85 }
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const [state, setState] = useState({
    success: false,
    error: false,
    loading: false,
  })
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    message: false,
  })
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  })

  /**
   * Validate the form
   * Check if the required fields are filled
   *
   */
  const validate = () => {
    const validation = {
      name: form.name.length === 0,
      email: form.email.length === 0,
      phone: false,
      message: form.message.length === 0,
    }

    setErrors(validation)

    return validation.name || validation.email || validation.message
  }

  /**
   * Submit function
   * Execute the action when a form is submitted,
   *
   * @param {Object} event
   */
  const submit = event => {
    event.preventDefault()
    const hasErrors = validate()
    if (hasErrors) return

    setState({ ...state, loading: true })

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "subject": "Consulta de Abogado de Guatemala via Website",
        "form-name": "contact-leads",
        ...form,
      }),
    })
      .then(() => setState({ ...state, loading: false, success: true }))
      .catch(() =>
        setState({
          ...state,
          loading: false,
          success: false,
          error: "There was an error submitting your form, please try again",
        })
      )
  }

  /**
   * On Change Form
   * Update the form state with the latest value of the field
   *
   * @param {Object} event
   */
  const onChangeForm = ({ target }) => {
    const { name, value } = target
    setForm({
      ...form,
      [name]: value,
    })
  }

  return (
    <BackgroundImage
      Tag="section"
      className="contact-form"
      fluid={image.sharp.fluid}
      backgroundColor={`#282d3a`}
    >
      <div className="container text-center">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-8 col-md-12 d-flex flex-column">
            <h3>
              Contactenos <br /> ¡Haga su cita Hoy!
            </h3>

            <div className="contact-form__phone">
              <FaPhoneAlt className="phone__icon" />
              <span>Llamenos al:</span>
              <a href={`tel:${phone.replace(/[^\w\s]/gi, "")}`}>{phone}</a>
            </div>

            <ul className="contact-form__info">
              <li>
                <a href={`mailto:${email}`}>
                  <FaRegEnvelopeOpen />
                  <span>{email}</span>
                </a>
              </li>
              <li>
                <span>Lunes a Viernes 9am - 6pm</span>
                <FaRegClock />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="contact-form__form">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="contact-form__inner">
                <h3>Evaluamos su caso</h3>
                <p>
                  Trabajamos en todos y cada uno de los departamentos y
                  municipios de Guatemala.
                </p>

                <form
                  className="container-fluid"
                  action="/contact-success/"
                  name="contact-leads"
                  netlify-honeypot="bot-field"
                  data-netlify="true"
                  onSubmit={submit}
                >
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div
                        className={`contact-form__field ${
                          errors.name ? "invalid" : ""
                        }`}
                      >
                        <label className="sr-only" htmlFor="name">
                          Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Nombre"
                          value={form.name}
                          onChange={onChangeForm}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div
                        className={`contact-form__field ${
                          errors.email ? "invalid" : ""
                        }`}
                      >
                        <label className="sr-only" htmlFor="email">
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email"
                          value={form.email}
                          onChange={onChangeForm}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div
                        className={`contact-form__field ${
                          errors.phone ? "invalid" : ""
                        }`}
                      >
                        <label className="sr-only" htmlFor="phone">
                          Phone
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          id="phone"
                          placeholder="Telefono"
                          value={form.phone}
                          onChange={onChangeForm}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div
                        className={`contact-form__field ${
                          errors.message ? "invalid" : ""
                        }`}
                      >
                        <label className="sr-only" htmlFor="message">
                          Ingrese su mensaje aqui
                        </label>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Ingrese su mensaje aqui"
                          value={form.message}
                          onChange={onChangeForm}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="text-center mb-0">
                        <button
                          type="submit"
                          className="btn btn-red"
                          disabled={state.loading}
                        >
                          Agende su cita &nbsp;&nbsp;
                          <FaLongArrowAltRight />
                        </button>
                      </div>
                    </div>

                    {state.error && (
                      <div className="col-md-12 text-center">
                        <p>{state.error}</p>
                      </div>
                    )}

                    {state.success && (
                      <div className="col-md-12 text-center">
                        <p>{`The form was submitted succesfully`}</p>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default ContactForm
